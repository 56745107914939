@import '_colours';
@import '_sizes';

html, body, div, article, footer, header, nav, section {
    display: block;  /* HTML5 elements default to inline in old IE */
    margin: 0;
    border: none;
    padding: 0;
    color: $text-colour;
    font-family: $font-family;
    font-size: $font-size;
    font-style: normal;
    font-weight: normal;
}

body {
    margin: $body-margin;
    text-align: center;
}

body > header {
    nav {
        text-align: right;
        font-size: $header-links-font-size;
    }

    > a {
        line-height: 50px;
        color: $header-title-colour;
        font-size: $header-title-font-size;
        text-decoration: none;
    }
}

$block-width: 300px;
$block-spacing: 50px;

#content {
    $width-1: 1 * ($block-width + $block-spacing);
    $width-2: 2 * ($block-width + $block-spacing);
    $width-3: 3 * ($block-width + $block-spacing);

    margin: $block-spacing auto 0 auto;
    width: $width-3;

    @media (max-width: $width-3 - 1) {
        width: $width-2;
    }

    @media (max-width: $width-2 - 1) {
        width: $width-1;
    }
}

#content a {
    float: left;
    width: $block-width;
    margin: 0 ($block-spacing / 2) $block-spacing ($block-spacing / 2);
    text-decoration: none;
}

article {
    text-align: right;

    header {
        float: right;
        margin-right: 2 * $border-width;
        @include chevron;
        font-size: $tab-font-size;
        font-weight: bold;
    }

    div {
        clear: right;
        height: 34px;
        border-width: $border-width;
        border-radius: 2 * $border-width;
        border-style: solid;
        padding: $border-width;
        text-align: left;
        background-color: white;
    }
}

@each $theme, $colour, $text in $colours {
    article.#{$theme} {
        header {
            color: unquote($text);
            background-color: $colour;
            background-image: url(../images/chevrons/#{$text}_empty.png);
        }
        div { border-color: $colour }
    }

    a:hover article.#{$theme} header {
        background-image: url(../images/chevrons/#{$text}_full.png);
    }
}

footer {
    clear: both;
    font-size: $footer-font-size;
}
