/*******************************
            Colours
*******************************/

/*-------------------
       General
--------------------*/

$text-colour: black;
$header-title-colour: black;

/*-------------------
    Theme colours
--------------------*/

$gray: rgb(198, 198, 198);

$colours: (
    'red'    rgb(204,  51,  51) 'white' rgb(243, 204, 204),
    'green'  rgb( 51, 204,   0) 'white' rgb(204, 243, 192),
    'orange' rgb(255, 153,   0) 'white' rgb(255, 230, 192),
    'blue'   rgb( 51,  51, 204) 'white' rgb(204, 204, 243),
    'yellow' rgb(255, 255,  51) 'black' rgb(255, 255, 204),
    'gray'   $gray                'black' rgb(243, 243, 243),
    'black'  rgb(  0,   0,   0) 'white' rgb(192, 192, 192),
);
