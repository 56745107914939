/*******************************
             Sizes
*******************************/

/*-------------------
       General
--------------------*/

$font-family: Verdana, Tahoma, Arial, sans-serif;
$font-size: 14px;
$body-margin: 5px;
$border-width: 10px;

/*-------------------
       Header
--------------------*/

$header-title-font-size: 40px;
$header-links-font-size: 12px;

/*-------------------
        Tabs
--------------------*/

$tab-height: 24px;
$tab-font-size: 18px;

@mixin chevron {
    line-height: $tab-height;
    padding-right: $tab-height / 3;
    padding-left: $tab-height;
    background-repeat: no-repeat;
    background-position: left;
    background-size: $tab-height $tab-height;
}

/*-------------------
       Content
--------------------*/

$sidebar-width: 240px;
$content-indent: 24px;
$content-margin: 1.2em;

/*-------------------
       Footer
--------------------*/

$footer-font-size: 12px;
